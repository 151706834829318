import React from 'react'
import vg from "../Assets/user-3.jpg";
import {AiFillAmazonCircle,AiFillGoogleCircle,AiFillInstagram,AiFillYoutube} from "react-icons/ai";
const Home = () => {
  return (
    <>
        <div className='home' id='home'>
        <main>
        <h1>Henmai</h1>
        <p>Solution to all your problems</p>
        </main>
    </div>
    <div className="home2">
        <img src={vg} alt="Graphics"/>
        <div>
            <p>
                “We are all like fireworks: We climb, we shine and always go our separate ways and become further apart. But even when that time comes, let’s not disappear like a firework and continue to shine… forever.” 
            </p>
        </div>

    </div>
    <div className="home3" id="about">
        <div>

        <h1>what is it all about?</h1>
        <p>need to be filled!</p>
    </div>
    </div>
    <div className="home4" id="brands">
        <div>
           <h1>Brands</h1>
            <article>
                <div style={{
                    animationDelay:"0.3s"}}>
                   <AiFillGoogleCircle/>
                   <p>Google</p>
                </div>
                <div style={{
                    animationDelay:"0.5s"}}>
                   <AiFillAmazonCircle/>
                   <p>Amazon</p>
                </div>
                <div style={{
                    animationDelay:"0.7s"}}>
                   <AiFillYoutube/>
                   <p>Youtube</p>
                </div>
                <div style={{
                    animationDelay:"1s"}}>
                   <AiFillInstagram/>
                   <p>Instagram</p>
                </div>
            </article>
        </div>
    </div>
    </>
  )
}

export default Home